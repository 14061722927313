import React from 'react';
import { useLocation } from 'react-router-dom';

const Header = () => {
  const location = useLocation();
  const pageTitle = getPageTitle(location.pathname);

  // Function to get the page title based on the pathname
  function getPageTitle(pathname) {
    // Modify this logic based on your URL structure and requirements
    const slug = pathname.split('/').pop(); // Extract the last part of the pathname as the slug
    const pageTitleMapping = {
      'about': 'About Us',
      'contact': 'Contact Us',
      'page1' : 'Page 1 ',
      'andalusia-morocco': 'Andalusia Morocco',
      'magical-south' : 'magical south',
      'morocco-grand-tour' : 'the grand tour',
      'imperial-tours' : 'imperial cities'
      // Add more page titles as needed
    };
    return pageTitleMapping[slug] || 'Page Title'; // Default to 'Page Title' if no match found
  }

  return (
    <section className="inner-banner-wrap">
      <div className="inner-baner-container">
        <div className="container">
          <div className="inner-banner-content">
            <h1 className="inner-title">{pageTitle}</h1>
          </div>
        </div>
      </div>
      <div className="inner-shape"></div>
    </section>
  );
};

export default Header;
