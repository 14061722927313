import ContactInfo from "../components/ContactInfo";
import Header from "../components/HeaderContact";
import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';


function Contact(){

  const [error, setError] = useState(false);
  const [isLoading, setisLoading] = useState(false);


  const [confirmation, setConfirmation] = useState(false)


  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_vvwwpzr', 'template_4qmz0aq', form.current, '4So6LoZztqYUUyOWf')
      .then((result) => {
        setisLoading(true);
        setConfirmation(true);
      }, (error) => {
          console.log(error.text);
      });
      

  };

  
      if (confirmation) {
          return (
            <><Header /><div className="contact-page-section">
              <div className="contact-form-inner">
                <div className="container">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="contact-from-wrap">
                        <div className="section-heading">
                          <h2>We will get back to you in the shortest notice</h2>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-24 h-24 text-green-400 mt-5  ">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>

                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="contact-detail-wrap">
                        <h3>Need help ? Feel free to contact us !</h3>
                        <p></p>
                        <div className="details-list">
                          <ul>
                            <li>
                              <span className="icon">
                                <i className="fas fa-map-signs" />
                              </span>
                              <div className="details-content">
                                <h4>Location Address</h4>
                                <span>N°15, 4éme Etage, Imm. Inflass, Bd Abderrahim Bouabid, Agadir 80000, Morocco</span>
                              </div>
                            </li>
                            <li>
                              <span className="icon">
                                <i className="fas fa-envelope-open-text" />
                              </span>
                              <div className="details-content">
                                <h4>Email Address</h4>
                                <span>booking@emotions-morocco.com</span>
                              </div>
                            </li>
                            <li>
                              <span className="icon">
                                <i className="fas fa-phone-volume" />
                              </span>
                              <div className="details-content">
                                <h4>Phone Number</h4>
                                <span>Telephone: +(212) 5282 370 38 / Mobile: +(212) 6245 887 41</span>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="contct-social social-links">
                          <h3>Follow us on social media..</h3>
                          <ul>
                            <li><a href="#"><i className="fab fa-facebook-f" aria-hidden="true" /></a></li>
                            <li><a href="#"><i className="fab fa-twitter" aria-hidden="true" /></a></li>
                            <li><a href="#"><i className="fab fa-instagram" aria-hidden="true" /></a></li>
                            <li><a href="#"><i className="fab fa-linkedin" aria-hidden="true" /></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="map-section">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1720.3876704137851!2d-9.575389760327118!3d30.41411530590901!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xdb3b72611671fe9%3A0x632071efd7474adf!2sIncoming%20emotions%20Morocco!5e0!3m2!1sen!2str!4v1683833238777!5m2!1sen!2str" style={{ width: '100vw' }} height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </div></>
          )
      }
    return (
       <>
        <Header />
    
          <div className="contact-page-section">
            <div className="contact-form-inner">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <div className="contact-from-wrap">
                      <div className="section-heading">
                        <h5 className="dash-style">GET IN TOUCH</h5>
                        <h2>CONTACT US TO GET MORE INFO</h2>
                        <p className="hidden">Aperiam sociosqu urna praesent, tristique, corrupti condimentum asperiores platea ipsum ad arcu. Nostrud. Esse? Aut nostrum, ornare quas provident laoreet nesciunt odio voluptates etiam, omnis.</p>
                      </div>
                      <form action="" ref={form} class=" mt-8 mb-0 max-w-md space-y-4" onSubmit={sendEmail}>
                        <div>
                            <label htmlFor="name" class="sr-only">Full Name</label>
                            <div class="relative">
                                <input type="text" name="from_name" id="name" autoComplete="name" class="my-2  w-full rounded-lg border-gray-200 p-4 pr-12 text-sm shadow-sm text-gray-900" placeholder="Enter your name."  />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="email" class="sr-only">Email</label>
  
                            <div class="relative">
                                <input type="email" name="reply_to" id="email" autoComplete="email" class=" w-full rounded-lg border-gray-200 p-4 pr-12 text-sm shadow-sm text-gray-900" placeholder="Your email"  required/>
                                <span class="absolute inset-y-0 right-4 inline-flex items-center">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="h-5 w-5 text-gray-400"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            stroke-width="2"
                                            d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                                    </svg>
                                </span>
                            </div>
                        </div>
  
                        <div>
                            <label htmlFor="phone" class="sr-only">Phone Number</label>
                            <div class="relative">
                                <input type="text" name="phone_number" id="phone" autoComplete="phone" class="my-2 w-full rounded-lg border-gray-200 p-4 pr-12 text-sm shadow-sm text-gray-900" placeholder="+44 ...."  />
                                <span class="absolute inset-y-0 right-4 inline-flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                        class="h-5 w-5 text-gray-400"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                                    </svg>
                                </span>
                            </div>
                        </div>
                        <div>
                            <label htmlFor="password" class="sr-only">Subject</label>
                            <div class="relative">
                                <textarea type="text" name="message" id="subject" autoComplete="subject" class="my-2 w-full rounded-lg border-gray-200 p-4 pr-12 text-sm shadow-sm text-gray-900" placeholder="Subject"  />
                            </div>
                        </div>
  
                        <div class="flex items-center justify-between">
                        {error && <p>{error}</p>}
                          {isLoading ? (
                             <button disabled type="button" class="ml-3 inline-block rounded-lg btn-15 button-text px-5 py-3 text-sm font-medium text-white">
                             <svg aria-hidden="true" role="status" class="inline w-4 h-4 mr-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                             <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                             <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2"/>
                             </svg>
                             Loading...
                         </button>
                          ) : (
                              <button class="ml-3 inline-block rounded-lg btn-15 button-text px-5 py-3 text-sm font-medium text-white" type="submit" value="Send">
                              Send
                              </button>
                          )}
                          {confirmation && <p>Thank you for your message!</p>}
                        </div>
                    </form>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="contact-detail-wrap">
                      <h3>Need help ?? Feel free to contact us !</h3>
                      <p></p>
                      <div className="details-list">
                        <ul>
                          <li>
                            <span className="icon">
                              <i className="fas fa-map-signs" />
                            </span>
                            <div className="details-content">
                              <h4>Location Address</h4>
                              <span>N°15, 4éme Etage, Imm. Inflass, Bd Abderrahim Bouabid, Agadir 80000, Morocco</span>
                            </div>
                          </li>
                          <li>
                            <span className="icon">
                              <i className="fas fa-envelope-open-text" />
                            </span>
                            <div className="details-content">
                              <h4>Email Address</h4>
                              <span>booking@emotions-morocco.com</span>
                            </div>
                          </li>
                          <li>
                            <span className="icon">
                              <i className="fas fa-phone-volume" />
                            </span>
                            <div className="details-content">
                              <h4>Phone Number</h4>
                              <span>Telephone: +(212) 5282 370 38 / Mobile: +(212) 6245 887 41</span>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="contct-social social-links">
                        <h3>Follow us on social media..</h3>
                        <ul>
                          <li><a href="#"><i className="fab fa-facebook-f" aria-hidden="true" /></a></li>
                          <li><a href="#"><i className="fab fa-twitter" aria-hidden="true" /></a></li>
                          <li><a href="#"><i className="fab fa-instagram" aria-hidden="true" /></a></li>
                          <li><a href="#"><i className="fab fa-linkedin" aria-hidden="true" /></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="map-section">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1720.3876704137851!2d-9.575389760327118!3d30.41411530590901!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xdb3b72611671fe9%3A0x632071efd7474adf!2sIncoming%20emotions%20Morocco!5e0!3m2!1sen!2str!4v1683833238777!5m2!1sen!2str" style={{width:'100vw'}} height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
       </>
    );
    }

export default Contact;