

import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';


const Navbar = () => {
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive(!isActive);
  };

    return (
      <div className="navigation" data-controller="hello">
      <div className="px-6 pt-6 lg:px-8">
        <nav className="flex items-center justify-between" aria-label="Global">
          <div className="flex lg:flex-1">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Emotions Morocco</span>
              <LazyLoadImage className="logo" src="./img/emotions-morocco-logo.webp" alt="Emotions Morocco" />
            </a>
          </div>  
          <div className="flex lg:hidden">
            <button type="button" className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-100" 
             onClick={handleClick} data-hello-target="burger">
              <span className="sr-only">Open main menu</span>
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
              </svg>
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            <a href="/#package" className=" font-bold leading-6 menu-item ">OUR TOURS</a>
    
            <a href="/#section" className=" font-bold leading-6 menu-item">LUXURY TRAVEL</a>
    
            <a href="/about" className=" font-bold leading-6 menu-item">ABOUT US</a>

    
            <a href="/contact" className=" font-bold leading-6 menu-item">CONTACT</a>
    
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <a href="#" className="text-white font-bold leading-6 " target="_blank"> <span aria-hidden="true">&rarr;</span></a>
          </div>
        </nav>

        <div role="dialog" aria-modal="true">
          <div focus="true" className={`fixed inset-0 z-10 overflow-y-auto px-6 py-6 bg-zinc-900 lg:hidden ${isActive ? 'is-active' : 'hidden'}`}>
            <div className="flex items-center justify-between">
              <a href="https://emotions-morocco.com" className="-m-1.5 p-1.5">
                <span className="sr-only">Emotions Morocco</span>
                <LazyLoadImage className="logoMobile" src="./img/emotions-morocco-logo.webp" alt="emotions morocco" />
              </a>
              <button type="button" className="-m-2.5 rounded-md p-2.5 text-gray-700"   onClick={handleClick}>
                <span className="sr-only">Close menu</span>
                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  <a href="/" className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7  hover:bg-gray-400/10">HOME</a>
                      <a href="/about" className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7  hover:bg-gray-400/10">ABOUT US</a>
    
                  <a href="/contact" className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7  hover:bg-gray-400/10">CONTACT</a>
                </div>
                <div className="py-6">
                  <a href="#" target="_blank" className="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-6  hover:bg-gray-400/10"></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    )
}

export default Navbar