const HeaderContact = () => {
    return (
        <section className="inner-banner-wrap">
               <div className="inner-baner-container">
                  <div className="container">
                     <div className="inner-banner-content">
                        <h1 className="inner-title">CONTACT</h1>
                     </div>
                  </div>
               </div>
               <div className="inner-shape"></div>
        </section>
    );
    }

export default HeaderContact;