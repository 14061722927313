const Discover = () => (

<section className="home-about-section">
      <div className="container">
         <div className="row">
            <div className="col-lg-7">
               <div className="about-img-wrap">
                  <div className="about-img-left">
                     <div className="about-content secondary-bg d-flex flex-wrap">
                        <h3>Rediscover the world with us!</h3>
                        <a href="/contact" className="button-primary">LEARN MORE</a>
                     </div>
                     <div className="about-img">
                        <img src="/img/portugal-view.webp" className="page-img" alt=""/>
                     </div>
                  </div>
                  <div className="about-img-right">
                     <div className="about-img">
                        <img src="/img/marrackech-view.webp" className="page-img" alt=""/>
                     </div>
                     <div className="about-img">
                        <img src="/img/spain-view1.webp" className="page-img" alt=""/>
                     </div>
                  </div>
               </div>
            </div>
            <div className="col-lg-5">
               <div className="banner-content section-heading">
                  <h5>INTRODUCTION ABOUT US</h5>
                  <h2 className="banner-title">EMOTIONS MOROCCO</h2>
                  <div className="title-icon-divider"><i className="fas fa-suitcase-rolling"></i></div>
               </div>
               <div className="about-service-container">
                  <div className="about-service">
                     <div className="about-service-icon">
                     <i class="icon-solid fa-solid fa-location-dot"></i>
                     </div>
                     <div className="about-service-content">
                        <h4>Adventure</h4>
                        <p>Experience the thrill of exploring new destinations and pushing your boundaries with our carefully curated adventure packages.</p>
                     </div>
                  </div>
                  <div className="about-service">
                     <div className="about-service-icon">
                        <img src="https://demo.bosathemes.com/html/travele/assets/images/icon16.png" alt=""/>
                     </div>
                     <div className="about-service-content">
                        <h4>Cultural immersion</h4>
                        <p> Immerse yourself in the rich history, diverse traditions, and vibrant cultures of different countries with our cultural immersion packages.</p>
                     </div>
                  </div>
                  <div className="about-service">
                     <div className="about-service-icon">
                        <img src="https://demo.bosathemes.com/html/travele/assets/images/icon17.png" alt=""/>
                     </div>
                     <div className="about-service-content">
                        <h4>Tailored experiences</h4>
                        <p>We specialize in creating customized travel experiences that cater to your unique preferences and needs, ensuring that your trip is truly unforgettable.</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
               

)

export default Discover;