import React, { useState } from 'react';

const Package = () => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const handleImageLoad = () => {
    setImageLoaded(true);
  };
  return(
      <><hr class="hr hr-blurry py-5" /><section className="package-section" id="package">
      <div className="container">
        <div className="section-heading text-center">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h5 className="dash-style">EXPLORE GREAT PLACES</h5>
              <h2>Top-Selling Tour Packages</h2>
              <p>Discover the best of Morocco with our top-selling tour packages, including Sahara expeditions, Andalusia-Morocco fusions, and Imperial Treasures, offering a blend of vibrant culture, stunning landscapes, and fascinating history and architecture.</p>
            </div>
          </div>
        </div>
        <div className="package-inner">
          <div className="row">
          <div className="col-md-6">
      <div className="package-wrap">
        <figure className="feature-image">
          <a href="/detail/imperial-tours">
            {!imageLoaded && (
              <div className="absolute inset-0 flex items-center justify-center bg-gray-300">
                <div className="loader"></div>
              </div>
            )}
            <img
              src="/img/marrakech.webp"
              alt="img5"
              className={imageLoaded ? 'image-loaded' : 'image-loading'}
              onLoad={handleImageLoad}
            />
          </a>
        </figure>
        <div className="package-price hidden-content">
          <h6>
            <span>$1,900</span> / per person
          </h6>
        </div>
        <div className="package-content-wrap">
          <div className="package-meta text-center hidden-content">
            <ul>
              <li>7D/6N</li>
              <li>People: 5</li>
              <li>Malaysia</li>
            </ul>
          </div>
          <div className="package-content pt-4">
            <h3>
              <a href="/detail/imperial-tours">Classical Imperial Cities Tour</a>
            </h3>
            <div className="review-area hidden-content">
              <span className="review-text">(25 reviews)</span>
            </div>
            <p>
              Uncover the fascinating history and awe-inspiring architecture of Morocco's Imperial Cities on our Classical Imperial Cities Tour,
              where you'll visit ancient ruins, bustling markets, majestic palaces, and experience the timeless charm of this captivating country.
            </p>
            <div className="btn-wrap">
              <a href="/contact" className="button-text btn-15 text-white width-6">
                Book Now
              </a>{' '}
              <a href="/detail/imperial-tours" className="button-text width-6">
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
            <div className="col-md-6">
              <div className="package-wrap">
                <figure className="feature-image">
                  <a href="/detail/andalusia-morocco"><img src="/img/andalusia.webp" alt="img7" /></a>
                </figure>
                <div className="package-price hidden-content">
                  <h6><span>$2,000</span> / per person</h6>
                </div>
                <div className="package-content-wrap">
                  <div className="package-meta text-center hidden-content">
                    <ul>
                      <li>6D/5N</li>
                      <li>People: 6</li>
                      <li>Portugal</li>
                    </ul>
                  </div>
                  <div className="package-content pt-4">
                    <h3><a href="/detail/andalusia-morocco" className="text-bold">Andalusia-Morocco Tour</a></h3>
                    <div className="review-area hidden-content">
                      <span className="review-text">(22 reviews)</span>
                    </div>
                    <p>Experience the rich cultural fusion of Spain and Morocco on our Andalusia-Morocco Tour, where you'll wander through the charming streets of Seville and Granada, journey across the Strait of Gibraltar to explore the vibrant cities of Tangier and Marrakech, and discover the unique blend of influences that make these destinations truly unforgettable.</p>
                    <div className="btn-wrap">
                      <a href="/contact" className="button-text btn-15 text-white width-6">Book Now</a> <a href="/detail/andalusia-morocco" className="button-text width-6">Read More</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="package-wrap">
                <figure className="feature-image">
                  <a href="/detail/magical-south"><img src="/img/magical.webp" alt="img5" /></a>
                </figure>
                <div className="package-price hidden-content">
                  <h6><span>$1,900</span> / per person</h6>
                </div>
                <div className="package-content-wrap">
                  <div className="package-meta text-center hidden-content">
                    <ul>
                      <li>7D/6N</li>
                      <li>People: 5</li>
                      <li>Malaysia</li>
                    </ul>
                  </div>
                  <div className="package-content pt-4">
                    <h3><a href="/detail/magical-south">The Magic of The South</a></h3>
                    <div className="review-area hidden-content">
                      <span className="review-text">(25 reviews)</span>
                    </div>
                    <p>Discover the enchanting beauty of Morocco's southern desert region with our Magical South Trip, where you'll explore ancient cities, ride camels through the dunes, stargaze under the clear night sky, and create memories that will last a lifetime.</p>
                    <div className="btn-wrap">
                      <a href="/contact" className="button-text width-6 btn-15 text-white">Book Now</a> <a href="/detail/magical-south" className="button-text width-6">Read More</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="package-wrap">
                <figure className="feature-image">
                  <a href="/detail/morocco-grand-tour"><img src="/img/tour.webp" alt="img7" /></a>
                </figure>
                <div className="package-price hidden-content">
                  <h6><span>$2,000</span> / per person</h6>
                </div>
                <div className="package-content-wrap">
                  <div className="package-meta text-center hidden-content">
                    <ul>
                      <li>6D/5N</li>
                      <li>People: 6</li>
                      <li>Portugal</li>
                    </ul>
                  </div>
                  <div className="package-content pt-4">
                    <h3><a href="/detail/morocco-grand-tour">Morocco Grand Tour</a></h3>
                    <div className="review-area hidden-content">
                      <span className="review-text">(22 reviews)</span>
                    </div>
                    <p>Embark on a once-in-a-lifetime adventure and immerse yourself in the rich culture,
                      stunning landscapes, and vibrant cities of Morocco with our unforgettable Morocco Grand Tour.</p>
                    <div className="btn-wrap">
                      <a href="/contact" className="button-text width-6 btn-15 text-white">Book Now</a> <a href="/detail/morocco-grand-tour" className="button-text width-6">Read More</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="btn-wrap text-center mt-12">
            <a href="/contact" className="btn-15 text-white button-text p-3">VIEW ALL PACKAGES</a>
          </div>
        </div>
      </div>
    </section></>

    )
}

export default Package;