import './App.css';
import Footer from './components/Footer';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Navbar from './components/Navbar';
import TourDetails from './components/TourDetails';
import packageDetails from './api/packageDetails.json'


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          {packageDetails.map((page) => (
            <Route
              key={page.slug}
              path={`/detail/${page.slug}`}
              element={
                <TourDetails
                  title={page.title}
                  description={page.description}
                  image={page.image}
                />
              }
            />
          ))}
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;