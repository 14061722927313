import React, { useState, useEffect } from 'react';
import axios from 'axios';

function ContactInfo () {

  const [contacts, setContacts] = useState([])
    

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [status, setStatus] = useState("Submit");

  const handleSubmit = (e) => {
      e.preventDefault()
      if (email.length > 50) {
          setError('Slogan must be 50 characters or less')
      } else {
          setError('');
          setIsLoading(true);
      }
      if (email.length > 0 ) {
          axios.post('https://club.chambersforsport.com/contacts', {
              email: email,
              name: name,
              phone: phone,
              message: message

          })
              .then(res => {
                  // console.log(res)
                  setConfirmation(true);
                  setIsLoading(false);
              })
              .catch(err => {
                  console.log(err)
              })
      }
  }

  const [confirmation, setConfirmation] = useState(false)

    if (confirmation) {
        return (
          <><section className="relative flex flex-wrap lg:h-screen lg:items-center">
                <div className="w-full px-4 py-12 sm:px-6 sm:py-16 lg:w-1/2 lg:px-8 lg:py-24">
                    <div className="mx-auto max-w-lg text-center">
                        <h1 className="text-2xl font-bold sm:text-3xl text-white">Thank you for contacting us</h1>

                        <p className="mt-4 text-gray-500">
                            <a
                                href="/"
                                className="ml-3 inline-block rounded-lg bg-red-700 px-5 py-3 text-sm font-medium text-white"
                            >
                                Go Back
                            </a>
                        </p>
                    </div>
                </div>
            </section><div className="row">

                </div></>
        )
    }

      (
  
        <div className="contact-page-section">
          <div className="contact-form-inner">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="contact-from-wrap">
                    <div className="section-heading">
                      <h5 className="dash-style">GET IN TOUCH</h5>
                      <h2>CONTACT US TO GET MORE INFO</h2>
                      <p>Aperiam sociosqu urna praesent, tristique, corrupti condimentum asperiores platea ipsum ad arcu. Nostrud. Esse? Aut nostrum, ornare quas provident laoreet nesciunt odio voluptates etiam, omnis.</p>
                    </div>
                    <form action="" class="mx-auto mt-8 mb-0 max-w-md space-y-4" onSubmit={handleSubmit}>
                      <div>
                          <label for="name" class="sr-only">Full Name</label>
                          <div class="relative">
                              <input type="text" name="name" id="name" autoComplete="name" class="my-2  w-full rounded-lg border-gray-200 p-4 pr-12 text-sm shadow-sm text-gray-900" placeholder="Enter your name." onChange={(e) => setName(e.target.value)}  />
                          </div>
                      </div>
                      <div>
                          <label for="email" class="sr-only">Email</label>

                          <div class="relative">
                              <input type="email" name="email" id="email" autoComplete="email" class=" w-full rounded-lg border-gray-200 p-4 pr-12 text-sm shadow-sm text-gray-900" placeholder="Your email" onChange={(e) => setEmail(e.target.value)} />
                              <span class="absolute inset-y-0 right-4 inline-flex items-center">
                                  <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      class="h-5 w-5 text-gray-400"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                  >
                                      <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          stroke-width="2"
                                          d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                                  </svg>
                              </span>
                          </div>
                      </div>

                      <div>
                          <label for="phone" class="sr-only">Phone Number</label>
                          <div class="relative">
                              <input type="text" name="phone" id="phone" autoComplete="phone" class="my-2 w-full rounded-lg border-gray-200 p-4 pr-12 text-sm shadow-sm text-gray-900" placeholder="+44 ...."  onChange={(e) => setPhone(e.target.value)}/>
                              <span class="absolute inset-y-0 right-4 inline-flex items-center">
                                  <svg xmlns="http://www.w3.org/2000/svg"
                                      class="h-5 w-5 text-gray-400"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                  >
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                                  </svg>
                              </span>
                          </div>
                      </div>
                      <div>
                          <label for="password" class="sr-only">Subject</label>
                          <div class="relative">
                              <textarea type="text" name="subject" id="subject" autoComplete="subject" class="my-2 w-full rounded-lg border-gray-200 p-4 pr-12 text-sm shadow-sm text-gray-900" placeholder="Subject" onChange={(e) => setMessage(e.target.value)} />
                          </div>
                      </div>

                      <div class="flex items-center justify-between">
                      {error && <p>{error}</p>}
                        {isLoading ? (
                            <button disabled class="ml-3 inline-block rounded-lg bg-gray-400 px-5 py-3 text-sm font-medium text-white" type="submit">
                            Sending...
                            </button>
                        ) : (
                            <button class="ml-3 inline-block rounded-lg bg-red-700 px-5 py-3 text-sm font-medium text-white" type="submit">
                            Send
                            </button>
                        )}
                        {confirmation && <p>Thank you for your message!</p>}
                      </div>
                  </form>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="contact-detail-wrap">
                    <h3>Need help ?? Feel free to contact us !</h3>
                    <p></p>
                    <div className="details-list">
                      <ul>
                        <li>
                          <span className="icon">
                            <i className="fas fa-map-signs" />
                          </span>
                          <div className="details-content">
                            <h4>Location Address</h4>
                            <span>N°15, 4éme Etage, Imm. Inflass, Bd Abderrahim Bouabid, Agadir 80000, Morocco</span>
                          </div>
                        </li>
                        <li>
                          <span className="icon">
                            <i className="fas fa-envelope-open-text" />
                          </span>
                          <div className="details-content">
                            <h4>Email Address</h4>
                            <span>domain@company.com</span>
                          </div>
                        </li>
                        <li>
                          <span className="icon">
                            <i className="fas fa-phone-volume" />
                          </span>
                          <div className="details-content">
                            <h4>Phone Number</h4>
                            <span>Telephone: 619-270-8578 / Mobile: +(212) 1987 123 88</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="contct-social social-links">
                      <h3>Follow us on social media..</h3>
                      <ul>
                        <li><a href="#"><i className="fab fa-facebook-f" aria-hidden="true" /></a></li>
                        <li><a href="#"><i className="fab fa-twitter" aria-hidden="true" /></a></li>
                        <li><a href="#"><i className="fab fa-instagram" aria-hidden="true" /></a></li>
                        <li><a href="#"><i className="fab fa-linkedin" aria-hidden="true" /></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="map-section">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1720.3876704137851!2d-9.575389760327118!3d30.41411530590901!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xdb3b72611671fe9%3A0x632071efd7474adf!2sIncoming%20emotions%20Morocco!5e0!3m2!1sen!2str!4v1683833238777!5m2!1sen!2str" style={{width:'100vw'}} height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      )
}

export default ContactInfo;