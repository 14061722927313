import Header from "../components/Header";
import AboutSection from "../components/AboutSection";
const About = () => {
    return (
        <>
        <Header />
        <AboutSection />
        </>
    );
    }

export default About;