import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import SwiperCore, { Navigation, Pagination, EffectCube,Autoplay, EffectCreative  } from 'swiper';
import Section from '../components/Section';
import Package from '../components/Package';
import Discover from '../components/Discover';

SwiperCore.use([Navigation, Pagination, EffectCube,Autoplay, EffectCreative]);

const slides = [
  {
    title: 'MOROCCO',
    t: 'Adventures',
    content: ' Explore the best of Morocco on our top-selling tour packages, featuring ancient cities, stunning landscapes, and vibrant culture.',
    imageUrl: '/img/morocco-desert.webp',
  },
  {
    title: 'ANDALUSIA',
    t: 'Cultutral Fusions',
    content: 'Discover the perfect blend of Spanish and Moroccan cultures on our top-rated Andalusia-Morocco tours, featuring charming cities, vibrant landscapes, and rich history.',
    imageUrl: '/img/andalusia.webp',

  },
  {
    title: 'PORTUGAL',
    t: 'Discovery',
    content: 'Experience the charming cities, breathtaking coastline, and rich culture of Portugal on our top-rated touristique tour.',
    imageUrl: '/img/portugal-tour.webp',
  },
];

const Home = () => {
  return (
    <>   <Swiper
    speed={1500}
    loop={true}
    effect="slide" // Change effect to "slide"
    spaceBetween={0}
    slidesPerView={1}
    autoplay={{
      delay: 3000,
      disableOnInteraction: false,
    }}
    navigation={{
      prevEl: '.swiper-button-prev',
      nextEl: '.swiper-button-next',
    }}
    pagination={{
      clickable: true,
    }}
  >
    <div className="swiper-button-prev"></div>
    <div className="swiper-button-next"></div>
    {slides.map((slide) => (
      <SwiperSlide key={slide.title}>
        <div
          className="hero-slide"
          style={{ backgroundImage: `url(${slide.imageUrl})` }}
        >
          <div className="hero-slide-overlay"></div> {/* Add a new div for the overlay */}
          <div className="hero-slide-content">
            <h4>{slide.t}</h4>
            <h1>{slide.title}</h1>
            <p>{slide.content}</p>
          </div>
        </div>
      </SwiperSlide>
    ))}
  </Swiper>
    
    <Section />
    <div className='inner-shape'></div>
    <Discover />
    <Package />
    </>
  );
};

export default Home;
