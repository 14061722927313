

const AboutSection = () => {
    return (
    <section className="about-section about-page-section">
  <div className="about-service-wrap">
    <div className="container">
      <div className="section-heading">
        <div className="row align-items-end">
          <div className="col-lg-6">
            <h5 className="dash-style">OUR TOUR GALLERY</h5>
            <h2>Emotions continue to reach Morocco</h2>
          </div>
          <div className="col-lg-6">
            <div className="section-disc">
              <p>
              We were, and we are pleased to receive you in Spain and Portugal and we are now honoured to open for 
              you the doors to the kingdom of Morocco for a royal holiday.
              Our philosophy stays the same: making you feel at home and provide you with a life experience.
              </p>
              <p>

              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="about-service-container">
        <div className="row">
          <div className="col-lg-4">
            <div className="about-service">
              <div className="about-service-icon">
                <img src="https://demo.bosathemes.com/html/travele/assets/images/icon15.png" alt="icon15" />
              </div>
              <div className="about-service-content">
                <h4>Adventure</h4>
                <p>Experience the thrill of exploring new destinations and pushing your boundaries with our carefully curated adventure packages.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="about-service">
              <div className="about-service-icon">
                <img src="https://demo.bosathemes.com/html/travele/assets/images/icon16.png" alt="icon16" />
              </div>
              <div className="about-service-content">
                <h4>Cultural immersion</h4>
                <p>Immerse yourself in the rich history, diverse traditions, and vibrant cultures of different countries with our cultural immersion packages.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="about-service">
              <div className="about-service-icon">
                <img src="https://demo.bosathemes.com/html/travele/assets/images/icon17.png" alt="icon17" />
              </div>
              <div className="about-service-content">
                <h4>Tailored experiences</h4>
                <p>We specialize in creating customized travel experiences that cater to your unique preferences and needs, ensuring that your trip is truly unforgettable.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="about-video-wrap"
        style={{ backgroundImage: "url(/img/marrackech-view.webp)" }}
        >
        <div className="video-button">
            <a id="video-container" data-video-id="IUN664s7N-c">
            <i className="fas fa-play" />
            </a>
        </div>
        </div>
    </div>
  </div>
  <div className="client-section hidden-content">
    <div className="container">
      <div className="row">
        <div className="col-lg-8 offset-lg-2">
          <div className="section-heading text-center">
            <h5 className="dash-style">OUR ASSOCAITES</h5>
            <h2>PARTNER'S AND CLIENTS</h2>
            <p>
              Mollit voluptatem perspiciatis convallis elementum corporis quo
              veritatis aliquid blandit, blandit torquent, odit placeat.
              Adipiscing repudiandae eius cursus? Nostrum magnis maxime curae
              placeat.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="fullwidth-callback" style={{ backgroundImage: "url(/img/marrackech-view.webp)" }}>
    <div className="container">
      <div className="section-heading section-heading-white text-center">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <h5 className="dash-style">CALLBACK FOR MORE</h5>
            <h2>GO TRAVEL.DISCOVER. REMEMBER US!!</h2>
            <p>
            Our trusted associates share our commitment to delivering exceptional travel experiences, ensuring that you receive the highest level of service and quality.
            </p>
          </div>
        </div>
      </div>
      <div className="callback-counter-wrap">
        <div className="counter-item">
          <div className="counter-item-inner">
            <div className="counter-icon">
              <img src="https://demo.bosathemes.com/html/travele/assets/images/icon1.png" alt="icon1" />
            </div>
            <div className="counter-content">
              <span className="counter-no">
                <span className="counter">50</span>K+
              </span>{" "}
              <span className="counter-text">Satisfied Clients</span>
            </div>
          </div>
        </div>
        <div className="counter-item">
          <div className="counter-item-inner">
            <div className="counter-icon">
              <img src="https://demo.bosathemes.com/html/travele/assets/images/icon2.png" alt="icon2" />
            </div>
            <div className="counter-content">
              <span className="counter-no">
                <span className="counter">250</span>+
              </span>{" "}
              <span className="counter-text">Hotels</span>
            </div>
          </div>
        </div>
        <div className="counter-item">
          <div className="counter-item-inner">
            <div className="counter-icon">
              <img src="https://demo.bosathemes.com/html/travele/assets/images/icon3.png" alt="icon3" />
            </div>
            <div className="counter-content">
              <span className="counter-no">
                <span className="counter">15</span>K+
              </span>{" "}
              <span className="counter-text">Active Members</span>
            </div>
          </div>
        </div>
        <div className="counter-item">
          <div className="counter-item-inner">
            <div className="counter-icon">
              <img src="https://demo.bosathemes.com/html/travele/assets/images/icon4.png" alt="icon4" />
            </div>
            <div className="counter-content">
              <span className="counter-no">
                <span className="counter">50</span>+
              </span>{" "}
              <span className="counter-text">Tour Destnation</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
    );

};

export default AboutSection;