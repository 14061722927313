const Section = () => {
    return (
<section className="destination-section">
               <div className="container">
                  <div className="section-heading">
                     <div className="row align-items-end">
                        <div className="col-lg-7">
                           <h5 className="dash-style">POPULAR DESTINATIONS</h5>
                           <h2 className="text-white">TOP DESTINATIONS</h2>
                        </div>
                        <div className="col-lg-5">
                           <div className="section-disc">
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="destination-inner destination-three-column">
                     <div className="row">
                        <div className="col-lg-7">
                           <div className="row">
                              <div className="col-sm-6">
                                 <div className="desti-item overlay-desti-item">
                                    <figure className="desti-image">
                                       <img src="/img/morocco.jpg" alt=""/>
                                    </figure>
                                    <div className="meta-cat bg-meta-cat">
                                       <a href="/contact">MOROCCO</a>
                                    </div>
                                    <div className="desti-content">
                                       <h3>
                                          <a href="/contact">Marrackech</a>
                                       </h3>
                                    </div>
                                 </div>
                              </div>
                              <div className="col-sm-6">
                                 <div className="desti-item overlay-desti-item">
                                    <figure className="desti-image">
                                       <img src="/img/spain.jpg" alt=""/>
                                    </figure>
                                    <div className="meta-cat bg-meta-cat">
                                       <a href="/contact">SPAIN</a>
                                    </div>
                                    <div className="desti-content">
                                       <h3>
                                          <a href="/contact">Andalusia</a>
                                       </h3>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-lg-5">
                           <div className="row">
                              <div className="col-md-6 col-xl-12">
                                 <div className="desti-item overlay-desti-item">
                                    <figure className="desti-image">
                                       <img src="/img/portugal.jpg" alt=""/>
                                    </figure>
                                    <div className="meta-cat bg-meta-cat">
                                       <a href="/contact">PORTUGAL</a>
                                    </div>
                                    <div className="desti-content">
                                       <h3>
                                          <a href="/contact">Lisbon</a>
                                       </h3>
                                    </div>
                                 </div>
                              </div>
                              <div className="col-md-6 col-xl-12">
                                 <div className="desti-item overlay-desti-item">
                                    <figure className="desti-image">
                                       <img src="/img/explore.jpg" alt=""/>
                                    </figure>
                                    <div className="meta-cat bg-meta-cat">
                                       <a href="/contact">EXPLORE</a>
                                    </div>
                                    <div className="desti-content">
                                       <h3>
                                          <a href="/contact">The Magical South</a>
                                       </h3>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="btn-wrap text-center" style={{marginBottom:60,marginTop:60}}>
                        <a href="/contact" className="btn-primary p-3 btn-15 button-text">MORE DESTINATION</a>
                     </div>
                  </div>
               </div>
            </section>
    );
};

export default Section;